<template>
    <div class="page-container">
        <div class="header-container">
            <div class="header-wrap">
                <div class="header-logo">
                    <img class="header-image" src="../assets/logo.jpg" />
                </div>
                <div class="header-menus">
                    <router-link class="menus-link" to="/home">首页</router-link>
                    <router-link class="menus-link" to="/about">关于我们</router-link>
                    <router-link class="menus-link" to="/news">新闻中心</router-link>
                    <router-link class="menus-link" to="/career">人才招聘</router-link>
                    <router-link class="menus-link" to="/vendor">供应商合作</router-link>
                </div>
            </div>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrap">
                <CarouselComp :items="imageList" :interval="interval" />
            </div>
        </div>
        <div class="content-container">
            <router-view></router-view>
        </div>
        <div class="footer-container">
            <div class="footer-wrap">
                <div>Copyright 2023 {{companyName}} 版权所有 | <el-link :underline="false" style="color: #BFEFFF" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备{{icpNumber}}号</el-link></div>
                <div>联系我们 | 企业邮箱</div>
            </div>
        </div>
    </div>
</template>

<script>

    import {reactive, toRefs} from "vue";
    import {useStore} from "vuex";
    import CarouselComp from '../components/CarouselComp.vue'


    export default {
      name: 'IndexView',
        components: {
            CarouselComp
        },
        setup: function () {
            const store = useStore()
            const data = reactive({
                companyName: store.state.companyName,
                icpNumber: store.state.icpNumber,
                imageList: [
                    {
                        image: require('../assets/swiper01.jpg'),
                    },
                    {
                        image: require('../assets/swiper02.jpg'),
                    },
                    {
                        image: require('../assets/swiper03.jpg'),
                    }
                ],
                interval: 3000,
            })

            return {
                ...toRefs(data),
            }
        }
    }

</script>

<style>
    .page-container {
        position: relative;
        margin: 0 0;
        height: 100vh;
    }
    .header-container {
        margin-top: 0;
        width: 100%;
        height: 90px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .header-wrap {
        width: 1100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #1574B9;
    }
    .header-logo {
        margin-left: 10px;
        width: 190px;
        height: 75px;
    }
    .header-image {
        height: 100%;
    }
    .header-menus {
        margin-right: 40px;
        width: 480px;
        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menus-link {
        color: #BFEFFF;
        text-decoration: none;
    }
    .router-link-exact-active {
        color: #00BFFF;
        text-decoration: none;
    }
    .swiper-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .swiper-wrap {
        width: 1100px;
    }
    .content-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .footer-container {
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .footer-wrap {
        width: 1100px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        background-color: #1574B9;
        font-size: 14px;
        color: #BFEFFF;
    }

</style>