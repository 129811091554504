<template>
    <el-carousel :interval="interval" height="320px" width="100%">
        <el-carousel-item v-for="item in items" :key="item">
            <img :src="item.image" alt=""/>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
    export default {
        name: 'CarouselView',
        props: {
            items: {
                type: Array,
                required: true
            },
            interval: {
                type: Number,
                default: 5000
            }
        }
    }
</script>

<style scoped>



</style>
