import { createStore } from 'vuex'

export default createStore({
  state: {
    companyName: '广州海猫网络科技有限公司',
    icpNumber: '2023097301',
    userInfo: (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))) || {}
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, usrInfo) {
      state.userInfo = usrInfo
    }
  },
  actions: {
  },
  modules: {
  }
})
