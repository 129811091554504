import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    redirect: '/home',
    children: [
      {
        path: "/home",
        component: () => import('../views/HomeView.vue')
      },
      {
        path: "/about",
        component: () => import('../views/AboutView.vue'),
        meta:{
          title: '关于我们'
        }
      },
      {
        path: "/news",
        component: () => import('../views/NewsView.vue'),
        meta:{
          title: '新闻中心'
        }
      },
      {
        path: "/career",
        component: () => import('../views/CareerView.vue'),
        meta:{
          title: '人才招聘'
        }
      },
      {
        path: "/vendor",
        component: () => import('../views/VendorView.vue'),
        meta:{
          title: '供应商合作'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + '-' + store.state.companyName
  }else{
    document.title = store.state.companyName
  }
  next()
})
export default router
