<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  body, html {
    margin: 0;
  }
</style>